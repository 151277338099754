import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { HeroDisclaimerWrapper } from '../styles/HeroDisclaimer.styled';

const HeroDisclaimer = ({ disclaimerList }) => (
  <HeroDisclaimerWrapper>
    <Row className="hero-disc-row">
      <Col lg={8} className="hero-disc-col">
        {disclaimerList?.map(disc => (
          <div className="logo-disc-text" key={disc.disclaimerTitle}>
            {disc.disclaimerText}
          </div>
        ))}
      </Col>
    </Row>
  </HeroDisclaimerWrapper>
);

export default HeroDisclaimer;

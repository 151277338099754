import React from 'react';
import LottieWrapper from '../../SwitchComponent/LottieWrapper';
import { determineBackgroundClass } from '../utils/helpers';

const HeroLottie = ({ component, lottieRef }) => {
  const lottieClass = `lottie-bg ${determineBackgroundClass(component) ===
    'bg-charcoal' && 'pt-5'}`;

  return (
    <div className={lottieClass} ref={lottieRef}>
      <LottieWrapper path={component?.reference[0]?.image?.url} />
    </div>
  );
};

export default HeroLottie;
